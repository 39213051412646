import IGameObject from "../IGameObject";
import * as p2 from 'p2';
import P2Factory from "../../../../Services/P2/P2Factory";

class PhysicsActorComponent {
    private _go: IGameObject;
    private _body: p2.Body;
    private _enabled: boolean = true;
    private _p2Factory: P2Factory;

    get body(){
        return this._body;
    }

    constructor(p2Factory: P2Factory) {
        this._p2Factory = p2Factory;
    }

    init(go: IGameObject){
        this._body = this._p2Factory.body(go);
        this._body.addShape(this._p2Factory.box(this._go.width, this._go.height));
        this._enabled = true;
        return this;
    }

    createNew(go: IGameObject){
        return this.createEmpty().init(go);
    }

    createEmpty(){
        return new PhysicsActorComponent(this._p2Factory);
    }

    update(){
        if(this._enabled){
            this.synchronize();
        }
    }

    synchronize(){
        this.syncPos();
        this.syncAngle();
    }

    syncPos(){
        this._go.x = this._body.position[0];
        this._go.y = this._body.position[1];
    }

    syncAngle(){
        this._go.angle = this._body.angle;
    }

    destroy(){
     //   this._go = undefined;

    }

    
}

export default PhysicsActorComponent;