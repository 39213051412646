import * as p2 from 'p2';
import P2Factory from '../../../Services/P2/P2Factory';
import Events from '../Events';
import IGameObject from '../GameObjects/IGameObject';
import PhysicsActorComponent from '../GameObjects/Components/PhysicsActorComponent';

class Physics {
    private _initialized: boolean = false;
    private _world: p2.World;
    private _p2Factory: P2Factory;
    private _actors: PhysicsActorComponent[] = [];
    private _actorFact: PhysicsActorComponent;
    private _events: Events;

    constructor(p2Factory: P2Factory, actorFact: PhysicsActorComponent, events: Events){
        this._p2Factory = p2Factory; this._actorFact = actorFact; this._events = events;
    }

    start(){
        this._world = this._p2Factory.world();
        this._initialized = true;       
    }

    enable(go: IGameObject){
      //  this._p2Factory.box()
      this._actors.push(this._actorFact.createNew(go));
    }

    initLoop(){
        this._events.timer(()=>{
            this.synchAll();
        }, 100, this, -1);
    }

    synchAll(){
        for(let actor of this._actors){
            actor.synchronize();
        }
    }

    stopLoop(){

    }

    disable(actor: PhysicsActorComponent){
        this._actors.splice(this._actors.indexOf(actor), 1);
    }

    initWorld(){
        if(!this._world) this._world = this._p2Factory.world();
       // this.clearBodies();
    }

    clearWorld(){
        for(let actor of this._actors){
            this._world.removeBody(actor.body);
        }
        this._actors = [];
    }
}

export default Physics;