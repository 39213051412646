const transitions = {
    SLIDE: {
        LEFT: 'Slide.Left',
        RIGHT: 'Slide.Right',
        UP: 'Slide.Up',
        DOWN: 'Slide.Down'
    },
    DEFAULT: ''
}

export default transitions;