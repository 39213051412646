import SmartDepend from '../Dep/SmartDepend';

import Anim from '../../src/Game/Core/Data/Anim';
import FunObj from '../../src/Game/Core/Data/FunObj';
import Resource from '../../src/Game/Core/Data/Resource';
import Tween from '../../src/Game/Core/Data/Tween';
import Activities from '../../src/Game/Core/Engine/Activities/Activities';
import Physics from '../../src/Game/Core/Engine/Activities/Physics';
import SceneComponentsFactory from '../../src/Game/Core/Engine/Activities/SceneComponentsFactory';
import SceneEvents from '../../src/Game/Core/Engine/Activities/SceneEvents';
import AudioManager from '../../src/Game/Core/Engine/AudioManager';
import Debug from '../../src/Game/Core/Engine/Debug';
import EventNames from '../../src/Game/Core/Engine/EventNames';
import EventObject from '../../src/Game/Core/Engine/EventObject';
import Events from '../../src/Game/Core/Engine/Events';
import Expose from '../../src/Game/Core/Engine/Expose';
import GameConfig from '../../src/Game/Core/Engine/GameConfig';
import BaseGameObject from '../../src/Game/Core/Engine/GameObjects/BaseGameObject';
import Button from '../../src/Game/Core/Engine/GameObjects/Button';
import Camera from '../../src/Game/Core/Engine/GameObjects/Camera';
import ExtractComponent from '../../src/Game/Core/Engine/GameObjects/Components/ExtractComponent';
import FrameAnimationManager from '../../src/Game/Core/Engine/GameObjects/Components/FrameAnimationManager';
import CircleHitShape from '../../src/Game/Core/Engine/GameObjects/Components/HitShapes/CircleHitShape';
import HitShapes from '../../src/Game/Core/Engine/GameObjects/Components/HitShapes/HitShapes';
import PolyHitShape from '../../src/Game/Core/Engine/GameObjects/Components/HitShapes/PolyHitShape';
import RectHitShape from '../../src/Game/Core/Engine/GameObjects/Components/HitShapes/RectHitShape';
import TriangleHitShape from '../../src/Game/Core/Engine/GameObjects/Components/HitShapes/TriangleHitShape';
import InputHandler from '../../src/Game/Core/Engine/GameObjects/Components/InputHandler';
import ObjectCore from '../../src/Game/Core/Engine/GameObjects/Components/ObjectCore';
import ParentChildHandler from '../../src/Game/Core/Engine/GameObjects/Components/ParentChildHandler';
import PhysicsActorComponent from '../../src/Game/Core/Engine/GameObjects/Components/PhysicsActorComponent';
import ScaleHandler from '../../src/Game/Core/Engine/GameObjects/Components/ScaleHandler';
import SpineAnimationManager from '../../src/Game/Core/Engine/GameObjects/Components/SpineAnimationManager';
import TweenComponent from '../../src/Game/Core/Engine/GameObjects/Components/TweenComponent';
import ContainerObject from '../../src/Game/Core/Engine/GameObjects/ContainerObject';
import DraggableObject from '../../src/Game/Core/Engine/GameObjects/DraggableObject';
import Dropzone from '../../src/Game/Core/Engine/GameObjects/Dropzone';
import GOFactory from '../../src/Game/Core/Engine/GameObjects/GOFactory';
import Mask from '../../src/Game/Core/Engine/GameObjects/Mask';
import MenuBar from '../../src/Game/Core/Engine/GameObjects/MenuBar';
import SliceObject from '../../src/Game/Core/Engine/GameObjects/SliceObject';
import SpineObject from '../../src/Game/Core/Engine/GameObjects/SpineObject';
import SpriteObject from '../../src/Game/Core/Engine/GameObjects/SpriteObject';
import GOState from '../../src/Game/Core/Engine/GameObjects/State/GOState';
import GOStateMachine from '../../src/Game/Core/Engine/GameObjects/State/GOStateMachine';
import States from '../../src/Game/Core/Engine/GameObjects/State/States';
import TextObject from '../../src/Game/Core/Engine/GameObjects/TextObject';
import TextSpriteObject from '../../src/Game/Core/Engine/GameObjects/TextSpriteObject';
import VideoObject from '../../src/Game/Core/Engine/GameObjects/VideoObject';
import InputManager from '../../src/Game/Core/Engine/InputManager';
import KeyListener from '../../src/Game/Core/Engine/KeyListener';
import EditorScene from '../../src/Game/Core/Engine/LevelEditor/EditorScene';
import ExportData from '../../src/Game/Core/Engine/LevelEditor/ExportData';
import ImportData from '../../src/Game/Core/Engine/LevelEditor/ImportData';
import Inspector from '../../src/Game/Core/Engine/LevelEditor/Inspector';
import LevelEditor from '../../src/Game/Core/Engine/LevelEditor/LevelEditor';
import UIAccordion from '../../src/Game/Core/Engine/LevelEditor/UIAccordion';
import LevelManager from '../../src/Game/Core/Engine/LevelManager';
import Loader from '../../src/Game/Core/Engine/Loader';
import Loop from '../../src/Game/Core/Engine/Loop';
import MultiplayerHandler from '../../src/Game/Core/Engine/MultiplayerHandler';
import ScaleManager from '../../src/Game/Core/Engine/ScaleManager';
import ScriptHandler from '../../src/Game/Core/Engine/ScriptHandler';
import TransitionManager from '../../src/Game/Core/Engine/TransitionManager';
import TweenManager from '../../src/Game/Core/Engine/TweenManager';
import ActScripts from '../../src/Game/Core/Engine/Utils/ActScripts';
import ArrayHandler from '../../src/Game/Core/Engine/Utils/ArrayHandler';
import Collections from '../../src/Game/Core/Engine/Utils/Collections';
import Colors from '../../src/Game/Core/Engine/Utils/Colors';
import MathUtils from '../../src/Game/Core/Engine/Utils/MathUtils';
import Mixins from '../../src/Game/Core/Engine/Utils/Mixins';
import Text from '../../src/Game/Core/Engine/Utils/Text';
import Triangles from '../../src/Game/Core/Engine/Utils/Triangles';
import Utils from '../../src/Game/Core/Engine/Utils/Utils';
import Vectors from '../../src/Game/Core/Engine/Utils/Vectors';
import World from '../../src/Game/Core/Engine/World';
import Game from '../../src/Game/Core/Game';
import Circle from '../../src/Game/Core/Geom/Circle';
import Geom from '../../src/Game/Core/Geom/Geom';
import LineSegment from '../../src/Game/Core/Geom/LineSegment';
import Point from '../../src/Game/Core/Geom/Point';
import Polygon from '../../src/Game/Core/Geom/Polygon';
import Rect from '../../src/Game/Core/Geom/Rect';
import Triangle from '../../src/Game/Core/Geom/Triangle';
import AjaxLoader from '../../src/Game/Services/AjaxLoader';
import HwFactory from '../../src/Game/Services/Howler/HwFactory';
import HwLoader from '../../src/Game/Services/Howler/HwLoader';
import HwPlayer from '../../src/Game/Services/Howler/HwPlayer';
import ImgLoader from '../../src/Game/Services/ImgLoader';
import ObjectHandler from '../../src/Game/Services/ObjectHandler';
import P2Factory from '../../src/Game/Services/P2/P2Factory';
import PxFactory from '../../src/Game/Services/Pixi/PxFactory';
import PxGame from '../../src/Game/Services/Pixi/PxGame';
import PxLoader from '../../src/Game/Services/Pixi/PxLoader';
import PxPoint from '../../src/Game/Services/Pixi/PxPoint';
import PxText from '../../src/Game/Services/Pixi/PxText';
import Screen from '../../src/Game/Services/Screen';
import SndLoader from '../../src/Game/Services/SndLoader';
import UAE from '../../src/Game/UAE';




class ControlContainer {
  private _smartDepend: SmartDepend;

  private _Anim: any;
private _FunObj: any;
private _Resource: any;
private _Tween: any;
private _Activities: any;
private _Physics: any;
private _SceneComponentsFactory: any;
private _SceneEvents: any;
private _AudioManager: any;
private _Debug: any;
private _EventNames: any;
private _EventObject: any;
private _Events: any;
private _Expose: any;
private _GameConfig: any;
private _BaseGameObject: any;
private _Button: any;
private _Camera: any;
private _ExtractComponent: any;
private _FrameAnimationManager: any;
private _CircleHitShape: any;
private _HitShapes: any;
private _PolyHitShape: any;
private _RectHitShape: any;
private _TriangleHitShape: any;
private _InputHandler: any;
private _ObjectCore: any;
private _ParentChildHandler: any;
private _PhysicsActorComponent: any;
private _ScaleHandler: any;
private _SpineAnimationManager: any;
private _TweenComponent: any;
private _ContainerObject: any;
private _DraggableObject: any;
private _Dropzone: any;
private _GOFactory: any;
private _Mask: any;
private _MenuBar: any;
private _SliceObject: any;
private _SpineObject: any;
private _SpriteObject: any;
private _GOState: any;
private _GOStateMachine: any;
private _States: any;
private _TextObject: any;
private _TextSpriteObject: any;
private _VideoObject: any;
private _InputManager: any;
private _KeyListener: any;
private _EditorScene: any;
private _ExportData: any;
private _ImportData: any;
private _Inspector: any;
private _LevelEditor: any;
private _UIAccordion: any;
private _LevelManager: any;
private _Loader: any;
private _Loop: any;
private _MultiplayerHandler: any;
private _ScaleManager: any;
private _ScriptHandler: any;
private _TransitionManager: any;
private _TweenManager: any;
private _ActScripts: any;
private _ArrayHandler: any;
private _Collections: any;
private _Colors: any;
private _MathUtils: any;
private _Mixins: any;
private _Text: any;
private _Triangles: any;
private _Utils: any;
private _Vectors: any;
private _World: any;
private _Game: any;
private _Circle: any;
private _Geom: any;
private _LineSegment: any;
private _Point: any;
private _Polygon: any;
private _Rect: any;
private _Triangle: any;
private _AjaxLoader: any;
private _HwFactory: any;
private _HwLoader: any;
private _HwPlayer: any;
private _ImgLoader: any;
private _ObjectHandler: any;
private _P2Factory: any;
private _PxFactory: any;
private _PxGame: any;
private _PxLoader: any;
private _PxPoint: any;
private _PxText: any;
private _Screen: any;
private _SndLoader: any;
private _UAE: any;


  constructor() {
    this._smartDepend = new SmartDepend();

    this._addModules();
    this._addDepends();
  }

  public getMain(): Game {
    let spEntity = <Game>this._smartDepend.resolve(this._Game);

    return spEntity;
  }

  private _addModules() {
    this._Anim = this._smartDepend.addModule(Anim, false);
this._FunObj = this._smartDepend.addModule(FunObj, false);
this._Resource = this._smartDepend.addModule(Resource, false);
this._Tween = this._smartDepend.addModule(Tween, false);
this._Activities = this._smartDepend.addModule(Activities, false);
this._Physics = this._smartDepend.addModule(Physics, false);
this._SceneComponentsFactory = this._smartDepend.addModule(SceneComponentsFactory, false);
this._SceneEvents = this._smartDepend.addModule(SceneEvents, false);
this._AudioManager = this._smartDepend.addModule(AudioManager, true);
this._Debug = this._smartDepend.addModule(Debug, false);
this._EventNames = this._smartDepend.addModule(EventNames, false);
this._EventObject = this._smartDepend.addModule(EventObject, false);
this._Events = this._smartDepend.addModule(Events, true);
this._Expose = this._smartDepend.addModule(Expose, false);
this._GameConfig = this._smartDepend.addModule(GameConfig, true);
this._BaseGameObject = this._smartDepend.addModule(BaseGameObject, false);
this._Button = this._smartDepend.addModule(Button, false);
this._Camera = this._smartDepend.addModule(Camera, false);
this._ExtractComponent = this._smartDepend.addModule(ExtractComponent, false);
this._FrameAnimationManager = this._smartDepend.addModule(FrameAnimationManager, false);
this._CircleHitShape = this._smartDepend.addModule(CircleHitShape, false);
this._HitShapes = this._smartDepend.addModule(HitShapes, false);
this._PolyHitShape = this._smartDepend.addModule(PolyHitShape, false);
this._RectHitShape = this._smartDepend.addModule(RectHitShape, false);
this._TriangleHitShape = this._smartDepend.addModule(TriangleHitShape, false);
this._InputHandler = this._smartDepend.addModule(InputHandler, false);
this._ObjectCore = this._smartDepend.addModule(ObjectCore, false);
this._ParentChildHandler = this._smartDepend.addModule(ParentChildHandler, false);
this._PhysicsActorComponent = this._smartDepend.addModule(PhysicsActorComponent, false);
this._ScaleHandler = this._smartDepend.addModule(ScaleHandler, false);
this._SpineAnimationManager = this._smartDepend.addModule(SpineAnimationManager, false);
this._TweenComponent = this._smartDepend.addModule(TweenComponent, false);
this._ContainerObject = this._smartDepend.addModule(ContainerObject, false);
this._DraggableObject = this._smartDepend.addModule(DraggableObject, false);
this._Dropzone = this._smartDepend.addModule(Dropzone, false);
this._GOFactory = this._smartDepend.addModule(GOFactory, true);
this._Mask = this._smartDepend.addModule(Mask, false);
this._MenuBar = this._smartDepend.addModule(MenuBar, false);
this._SliceObject = this._smartDepend.addModule(SliceObject, false);
this._SpineObject = this._smartDepend.addModule(SpineObject, false);
this._SpriteObject = this._smartDepend.addModule(SpriteObject, false);
this._GOState = this._smartDepend.addModule(GOState, false);
this._GOStateMachine = this._smartDepend.addModule(GOStateMachine, false);
this._States = this._smartDepend.addModule(States, false);
this._TextObject = this._smartDepend.addModule(TextObject, false);
this._TextSpriteObject = this._smartDepend.addModule(TextSpriteObject, false);
this._VideoObject = this._smartDepend.addModule(VideoObject, false);
this._InputManager = this._smartDepend.addModule(InputManager, true);
this._KeyListener = this._smartDepend.addModule(KeyListener, false);
this._EditorScene = this._smartDepend.addModule(EditorScene, true);
this._ExportData = this._smartDepend.addModule(ExportData, false);
this._ImportData = this._smartDepend.addModule(ImportData, false);
this._Inspector = this._smartDepend.addModule(Inspector, false);
this._LevelEditor = this._smartDepend.addModule(LevelEditor, true);
this._UIAccordion = this._smartDepend.addModule(UIAccordion, false);
this._LevelManager = this._smartDepend.addModule(LevelManager, true);
this._Loader = this._smartDepend.addModule(Loader, true);
this._Loop = this._smartDepend.addModule(Loop, true);
this._MultiplayerHandler = this._smartDepend.addModule(MultiplayerHandler, false);
this._ScaleManager = this._smartDepend.addModule(ScaleManager, true);
this._ScriptHandler = this._smartDepend.addModule(ScriptHandler, true);
this._TransitionManager = this._smartDepend.addModule(TransitionManager, false);
this._TweenManager = this._smartDepend.addModule(TweenManager, false);
this._ActScripts = this._smartDepend.addModule(ActScripts, true);
this._ArrayHandler = this._smartDepend.addModule(ArrayHandler, false);
this._Collections = this._smartDepend.addModule(Collections, true);
this._Colors = this._smartDepend.addModule(Colors, true);
this._MathUtils = this._smartDepend.addModule(MathUtils, false);
this._Mixins = this._smartDepend.addModule(Mixins, true);
this._Text = this._smartDepend.addModule(Text, true);
this._Triangles = this._smartDepend.addModule(Triangles, false);
this._Utils = this._smartDepend.addModule(Utils, true);
this._Vectors = this._smartDepend.addModule(Vectors, true);
this._World = this._smartDepend.addModule(World, false);
this._Game = this._smartDepend.addModule(Game, true);
this._Circle = this._smartDepend.addModule(Circle, false);
this._Geom = this._smartDepend.addModule(Geom, false);
this._LineSegment = this._smartDepend.addModule(LineSegment, false);
this._Point = this._smartDepend.addModule(Point, false);
this._Polygon = this._smartDepend.addModule(Polygon, false);
this._Rect = this._smartDepend.addModule(Rect, false);
this._Triangle = this._smartDepend.addModule(Triangle, false);
this._AjaxLoader = this._smartDepend.addModule(AjaxLoader, true);
this._HwFactory = this._smartDepend.addModule(HwFactory, true);
this._HwLoader = this._smartDepend.addModule(HwLoader, true);
this._HwPlayer = this._smartDepend.addModule(HwPlayer, true);
this._ImgLoader = this._smartDepend.addModule(ImgLoader, true);
this._ObjectHandler = this._smartDepend.addModule(ObjectHandler, false);
this._P2Factory = this._smartDepend.addModule(P2Factory, false);
this._PxFactory = this._smartDepend.addModule(PxFactory, true);
this._PxGame = this._smartDepend.addModule(PxGame, true);
this._PxLoader = this._smartDepend.addModule(PxLoader, true);
this._PxPoint = this._smartDepend.addModule(PxPoint, false);
this._PxText = this._smartDepend.addModule(PxText, false);
this._Screen = this._smartDepend.addModule(Screen, true);
this._SndLoader = this._smartDepend.addModule(SndLoader, true);
this._UAE = this._smartDepend.addModule(UAE, false);

  }

  private _addDepends() {
    this._smartDepend.addDependency(this._Anim, this._Events);


this._smartDepend.addDependency(this._Tween, this._Events);


this._smartDepend.addDependency(this._Activities, this._SceneComponentsFactory);


this._smartDepend.addDependency(this._Physics, this._P2Factory);
this._smartDepend.addDependency(this._Physics, this._PhysicsActorComponent);
this._smartDepend.addDependency(this._Physics, this._Events);


this._smartDepend.addDependency(this._SceneComponentsFactory, this._SceneEvents);


this._smartDepend.addDependency(this._SceneEvents, this._Events);


this._smartDepend.addDependency(this._AudioManager, this._Loader);
this._smartDepend.addDependency(this._AudioManager, this._HwPlayer);
this._smartDepend.addDependency(this._AudioManager, this._Events);


this._smartDepend.addDependency(this._BaseGameObject, this._ObjectCore);
this._smartDepend.addDependency(this._BaseGameObject, this._ParentChildHandler);
this._smartDepend.addDependency(this._BaseGameObject, this._Screen);
this._smartDepend.addDependency(this._BaseGameObject, this._InputHandler);
this._smartDepend.addDependency(this._BaseGameObject, this._ScaleHandler);
this._smartDepend.addDependency(this._BaseGameObject, this._TweenComponent);
this._smartDepend.addDependency(this._BaseGameObject, this._ExtractComponent);


this._smartDepend.addDependency(this._Button, this._SpriteObject);


this._smartDepend.addDependency(this._Camera, this._MathUtils);
this._smartDepend.addDependency(this._Camera, this._GameConfig);
this._smartDepend.addDependency(this._Camera, this._Geom);
this._smartDepend.addDependency(this._Camera, this._InputManager);
this._smartDepend.addDependency(this._Camera, this._TweenComponent);


this._smartDepend.addDependency(this._ExtractComponent, this._Screen);


this._smartDepend.addDependency(this._FrameAnimationManager, this._Anim);
this._smartDepend.addDependency(this._FrameAnimationManager, this._Loader);


this._smartDepend.addDependency(this._CircleHitShape, this._Utils);
this._smartDepend.addDependency(this._CircleHitShape, this._Point);
this._smartDepend.addDependency(this._CircleHitShape, this._Circle);


this._smartDepend.addDependency(this._HitShapes, this._CircleHitShape);
this._smartDepend.addDependency(this._HitShapes, this._RectHitShape);
this._smartDepend.addDependency(this._HitShapes, this._PolyHitShape);
this._smartDepend.addDependency(this._HitShapes, this._TriangleHitShape);


this._smartDepend.addDependency(this._PolyHitShape, this._Point);
this._smartDepend.addDependency(this._PolyHitShape, this._Polygon);
this._smartDepend.addDependency(this._PolyHitShape, this._TriangleHitShape);


this._smartDepend.addDependency(this._RectHitShape, this._Rect);


this._smartDepend.addDependency(this._TriangleHitShape, this._Triangle);
this._smartDepend.addDependency(this._TriangleHitShape, this._Triangles);


this._smartDepend.addDependency(this._InputHandler, this._InputManager);


this._smartDepend.addDependency(this._ObjectCore, this._Screen);
this._smartDepend.addDependency(this._ObjectCore, this._ObjectHandler);
this._smartDepend.addDependency(this._ObjectCore, this._InputHandler);
this._smartDepend.addDependency(this._ObjectCore, this._Mask);
this._smartDepend.addDependency(this._ObjectCore, this._MathUtils);
this._smartDepend.addDependency(this._ObjectCore, this._Events);
this._smartDepend.addDependency(this._ObjectCore, this._Point);
this._smartDepend.addDependency(this._ObjectCore, this._Loop);


this._smartDepend.addDependency(this._PhysicsActorComponent, this._P2Factory);


this._smartDepend.addDependency(this._ScaleHandler, this._ScaleManager);


this._smartDepend.addDependency(this._TweenComponent, this._TweenManager);
this._smartDepend.addDependency(this._TweenComponent, this._Tween);


this._smartDepend.addDependency(this._ContainerObject, this._ObjectCore);
this._smartDepend.addDependency(this._ContainerObject, this._ParentChildHandler);
this._smartDepend.addDependency(this._ContainerObject, this._Screen);
this._smartDepend.addDependency(this._ContainerObject, this._InputHandler);
this._smartDepend.addDependency(this._ContainerObject, this._ScaleHandler);
this._smartDepend.addDependency(this._ContainerObject, this._TweenComponent);
this._smartDepend.addDependency(this._ContainerObject, this._Point);
this._smartDepend.addDependency(this._ContainerObject, this._ExtractComponent);


this._smartDepend.addDependency(this._DraggableObject, this._SliceObject);
this._smartDepend.addDependency(this._DraggableObject, this._SpineObject);
this._smartDepend.addDependency(this._DraggableObject, this._SpriteObject);
this._smartDepend.addDependency(this._DraggableObject, this._TextObject);
this._smartDepend.addDependency(this._DraggableObject, this._Loop);
this._smartDepend.addDependency(this._DraggableObject, this._Dropzone);
this._smartDepend.addDependency(this._DraggableObject, this._Point);
this._smartDepend.addDependency(this._DraggableObject, this._Events);
this._smartDepend.addDependency(this._DraggableObject, this._InputManager);


this._smartDepend.addDependency(this._Dropzone, this._Point);
this._smartDepend.addDependency(this._Dropzone, this._InputManager);
this._smartDepend.addDependency(this._Dropzone, this._PxGame);


this._smartDepend.addDependency(this._GOFactory, this._ObjectCore);
this._smartDepend.addDependency(this._GOFactory, this._ScriptHandler);
this._smartDepend.addDependency(this._GOFactory, this._SpriteObject);
this._smartDepend.addDependency(this._GOFactory, this._SliceObject);
this._smartDepend.addDependency(this._GOFactory, this._SpineObject);
this._smartDepend.addDependency(this._GOFactory, this._TextObject);
this._smartDepend.addDependency(this._GOFactory, this._TextSpriteObject);
this._smartDepend.addDependency(this._GOFactory, this._DraggableObject);
this._smartDepend.addDependency(this._GOFactory, this._ContainerObject);
this._smartDepend.addDependency(this._GOFactory, this._MenuBar);
this._smartDepend.addDependency(this._GOFactory, this._ScaleManager);
this._smartDepend.addDependency(this._GOFactory, this._Button);
this._smartDepend.addDependency(this._GOFactory, this._VideoObject);
this._smartDepend.addDependency(this._GOFactory, this._Screen);
this._smartDepend.addDependency(this._GOFactory, this._Camera);
this._smartDepend.addDependency(this._GOFactory, this._HitShapes);
this._smartDepend.addDependency(this._GOFactory, this._States);


this._smartDepend.addDependency(this._Mask, this._ObjectHandler);
this._smartDepend.addDependency(this._Mask, this._Screen);


this._smartDepend.addDependency(this._MenuBar, this._SpriteObject);
this._smartDepend.addDependency(this._MenuBar, this._GOStateMachine);
this._smartDepend.addDependency(this._MenuBar, this._ContainerObject);


this._smartDepend.addDependency(this._SliceObject, this._ObjectCore);
this._smartDepend.addDependency(this._SliceObject, this._ParentChildHandler);
this._smartDepend.addDependency(this._SliceObject, this._Screen);
this._smartDepend.addDependency(this._SliceObject, this._InputHandler);
this._smartDepend.addDependency(this._SliceObject, this._ScaleHandler);
this._smartDepend.addDependency(this._SliceObject, this._TweenComponent);
this._smartDepend.addDependency(this._SliceObject, this._ExtractComponent);


this._smartDepend.addDependency(this._SpineObject, this._ObjectCore);
this._smartDepend.addDependency(this._SpineObject, this._ParentChildHandler);
this._smartDepend.addDependency(this._SpineObject, this._Screen);
this._smartDepend.addDependency(this._SpineObject, this._InputHandler);
this._smartDepend.addDependency(this._SpineObject, this._ScaleHandler);
this._smartDepend.addDependency(this._SpineObject, this._SpineAnimationManager);
this._smartDepend.addDependency(this._SpineObject, this._TweenComponent);
this._smartDepend.addDependency(this._SpineObject, this._ExtractComponent);


this._smartDepend.addDependency(this._SpriteObject, this._ObjectCore);
this._smartDepend.addDependency(this._SpriteObject, this._ParentChildHandler);
this._smartDepend.addDependency(this._SpriteObject, this._Screen);
this._smartDepend.addDependency(this._SpriteObject, this._InputHandler);
this._smartDepend.addDependency(this._SpriteObject, this._ScaleHandler);
this._smartDepend.addDependency(this._SpriteObject, this._FrameAnimationManager);
this._smartDepend.addDependency(this._SpriteObject, this._TweenComponent);
this._smartDepend.addDependency(this._SpriteObject, this._ExtractComponent);


this._smartDepend.addDependency(this._GOStateMachine, this._GOState);


this._smartDepend.addDependency(this._States, this._GOState);
this._smartDepend.addDependency(this._States, this._GOStateMachine);


this._smartDepend.addDependency(this._TextObject, this._ObjectCore);
this._smartDepend.addDependency(this._TextObject, this._ParentChildHandler);
this._smartDepend.addDependency(this._TextObject, this._Screen);
this._smartDepend.addDependency(this._TextObject, this._InputHandler);
this._smartDepend.addDependency(this._TextObject, this._ScaleHandler);
this._smartDepend.addDependency(this._TextObject, this._TweenComponent);
this._smartDepend.addDependency(this._TextObject, this._ExtractComponent);


this._smartDepend.addDependency(this._TextSpriteObject, this._ObjectCore);
this._smartDepend.addDependency(this._TextSpriteObject, this._ParentChildHandler);
this._smartDepend.addDependency(this._TextSpriteObject, this._Screen);
this._smartDepend.addDependency(this._TextSpriteObject, this._InputHandler);
this._smartDepend.addDependency(this._TextSpriteObject, this._ScaleHandler);
this._smartDepend.addDependency(this._TextSpriteObject, this._TweenComponent);
this._smartDepend.addDependency(this._TextSpriteObject, this._ExtractComponent);


this._smartDepend.addDependency(this._VideoObject, this._ObjectCore);
this._smartDepend.addDependency(this._VideoObject, this._ParentChildHandler);
this._smartDepend.addDependency(this._VideoObject, this._Screen);
this._smartDepend.addDependency(this._VideoObject, this._InputHandler);
this._smartDepend.addDependency(this._VideoObject, this._ScaleHandler);
this._smartDepend.addDependency(this._VideoObject, this._TweenComponent);
this._smartDepend.addDependency(this._VideoObject, this._GameConfig);
this._smartDepend.addDependency(this._VideoObject, this._ExtractComponent);
this._smartDepend.addDependency(this._VideoObject, this._Events);


this._smartDepend.addDependency(this._InputManager, this._Events);
this._smartDepend.addDependency(this._InputManager, this._Loader);
this._smartDepend.addDependency(this._InputManager, this._Screen);
this._smartDepend.addDependency(this._InputManager, this._EventNames);
this._smartDepend.addDependency(this._InputManager, this._Point);
this._smartDepend.addDependency(this._InputManager, this._ScaleManager);
this._smartDepend.addDependency(this._InputManager, this._KeyListener);


this._smartDepend.addDependency(this._EditorScene, this._Loader);
this._smartDepend.addDependency(this._EditorScene, this._LevelManager);
this._smartDepend.addDependency(this._EditorScene, this._Loop);
this._smartDepend.addDependency(this._EditorScene, this._GOFactory);
this._smartDepend.addDependency(this._EditorScene, this._PxGame);
this._smartDepend.addDependency(this._EditorScene, this._UIAccordion);
this._smartDepend.addDependency(this._EditorScene, this._ExportData);
this._smartDepend.addDependency(this._EditorScene, this._Inspector);
this._smartDepend.addDependency(this._EditorScene, this._SceneEvents);
this._smartDepend.addDependency(this._EditorScene, this._MathUtils);
this._smartDepend.addDependency(this._EditorScene, this._ImportData);


this._smartDepend.addDependency(this._ImportData, this._Events);


this._smartDepend.addDependency(this._Inspector, this._Events);


this._smartDepend.addDependency(this._LevelEditor, this._Events);
this._smartDepend.addDependency(this._LevelEditor, this._EditorScene);


this._smartDepend.addDependency(this._UIAccordion, this._Loader);
this._smartDepend.addDependency(this._UIAccordion, this._PxFactory);
this._smartDepend.addDependency(this._UIAccordion, this._PxLoader);
this._smartDepend.addDependency(this._UIAccordion, this._Events);
this._smartDepend.addDependency(this._UIAccordion, this._GameConfig);
this._smartDepend.addDependency(this._UIAccordion, this._GOFactory);


this._smartDepend.addDependency(this._LevelManager, this._AudioManager);
this._smartDepend.addDependency(this._LevelManager, this._ScriptHandler);
this._smartDepend.addDependency(this._LevelManager, this._Utils);
this._smartDepend.addDependency(this._LevelManager, this._InputManager);
this._smartDepend.addDependency(this._LevelManager, this._GOFactory);
this._smartDepend.addDependency(this._LevelManager, this._Events);


this._smartDepend.addDependency(this._Loader, this._Resource);
this._smartDepend.addDependency(this._Loader, this._ImgLoader);
this._smartDepend.addDependency(this._Loader, this._SndLoader);
this._smartDepend.addDependency(this._Loader, this._AjaxLoader);
this._smartDepend.addDependency(this._Loader, this._GameConfig);
this._smartDepend.addDependency(this._Loader, this._Loop);
this._smartDepend.addDependency(this._Loader, this._Events);


this._smartDepend.addDependency(this._Loop, this._Events);
this._smartDepend.addDependency(this._Loop, this._FunObj);


this._smartDepend.addDependency(this._ScaleManager, this._GameConfig);


this._smartDepend.addDependency(this._ScriptHandler, this._ActScripts);
this._smartDepend.addDependency(this._ScriptHandler, this._Events);


this._smartDepend.addDependency(this._TransitionManager, this._TweenManager);
this._smartDepend.addDependency(this._TransitionManager, this._GameConfig);
this._smartDepend.addDependency(this._TransitionManager, this._Screen);
this._smartDepend.addDependency(this._TransitionManager, this._Events);
this._smartDepend.addDependency(this._TransitionManager, this._GOFactory);


this._smartDepend.addDependency(this._TweenManager, this._Tween);
this._smartDepend.addDependency(this._TweenManager, this._Loop);
this._smartDepend.addDependency(this._TweenManager, this._Events);


this._smartDepend.addDependency(this._ActScripts, this._Text);
this._smartDepend.addDependency(this._ActScripts, this._Collections);


this._smartDepend.addDependency(this._MathUtils, this._Triangles);


this._smartDepend.addDependency(this._Utils, this._ActScripts);
this._smartDepend.addDependency(this._Utils, this._Collections);
this._smartDepend.addDependency(this._Utils, this._Colors);
this._smartDepend.addDependency(this._Utils, this._Mixins);
this._smartDepend.addDependency(this._Utils, this._MathUtils);
this._smartDepend.addDependency(this._Utils, this._Text);
this._smartDepend.addDependency(this._Utils, this._Vectors);


this._smartDepend.addDependency(this._Vectors, this._Point);


this._smartDepend.addDependency(this._World, this._Screen);
this._smartDepend.addDependency(this._World, this._Events);


this._smartDepend.addDependency(this._Game, this._World);
this._smartDepend.addDependency(this._Game, this._Loop);
this._smartDepend.addDependency(this._Game, this._Loader);
this._smartDepend.addDependency(this._Game, this._Events);
this._smartDepend.addDependency(this._Game, this._ScaleManager);
this._smartDepend.addDependency(this._Game, this._Expose);
this._smartDepend.addDependency(this._Game, this._GameConfig);
this._smartDepend.addDependency(this._Game, this._LevelManager);
this._smartDepend.addDependency(this._Game, this._GOFactory);
this._smartDepend.addDependency(this._Game, this._Geom);
this._smartDepend.addDependency(this._Game, this._Utils);
this._smartDepend.addDependency(this._Game, this._TweenManager);
this._smartDepend.addDependency(this._Game, this._Debug);
this._smartDepend.addDependency(this._Game, this._LevelEditor);
this._smartDepend.addDependency(this._Game, this._Activities);


this._smartDepend.addDependency(this._Circle, this._Point);


this._smartDepend.addDependency(this._Geom, this._Circle);
this._smartDepend.addDependency(this._Geom, this._LineSegment);
this._smartDepend.addDependency(this._Geom, this._Point);
this._smartDepend.addDependency(this._Geom, this._Rect);
this._smartDepend.addDependency(this._Geom, this._Polygon);


this._smartDepend.addDependency(this._LineSegment, this._Point);


this._smartDepend.addDependency(this._Polygon, this._Point);


this._smartDepend.addDependency(this._Rect, this._Point);


this._smartDepend.addDependency(this._HwLoader, this._HwFactory);


this._smartDepend.addDependency(this._HwPlayer, this._Loader);


this._smartDepend.addDependency(this._ImgLoader, this._PxLoader);


this._smartDepend.addDependency(this._PxFactory, this._PxText);


this._smartDepend.addDependency(this._PxGame, this._PxFactory);
this._smartDepend.addDependency(this._PxGame, this._Loader);
this._smartDepend.addDependency(this._PxGame, this._Events);
this._smartDepend.addDependency(this._PxGame, this._GameConfig);


this._smartDepend.addDependency(this._PxLoader, this._PxFactory);


this._smartDepend.addDependency(this._PxText, this._PxPoint);


this._smartDepend.addDependency(this._Screen, this._PxGame);


this._smartDepend.addDependency(this._SndLoader, this._HwLoader);



  }

}

export default ControlContainer;